
































































































import Vue from 'vue';
const Ripple = require('vue-ripple-directive');
import axios from 'axios';
import { MonitorType } from './types';

interface EditMonitorType extends MonitorType {
  dirty?: boolean;
  levelSeq?: string;
}

export default Vue.extend({
  components: {},
  directives: {
    Ripple,
  },
  data() {
    const columns = [
      {
        key: '_id',
        label: '代碼',
        formatter: (v: string) => {
          if (v === 'WD_SPEED' || v === 'WD_DIR') return `${v} (向量計算)`;
          else return v;
        },
      },
      {
        key: 'desp',
        label: '名稱',
        sortable: true,
      },
      {
        key: 'value',
        label: '現值',
      },
      {
        key: 'test',
        label: '測試',
      },
      {
        key: 'measuringBy',
        label: '儀器',
        formatter: (
          value: null | Array<string>,
          key: string,
          item: Array<string>,
        ) => {
          if (value !== null) return `${value.join(', ')}`;
          else return '';
        },
      },
    ];
    const monitorTypes = Array<EditMonitorType>();

    const form = {
      _id: 'SIGNAL1',
      desp: '訊號1',
    };
    return {
      display: false,
      columns,
      monitorTypes,
      editingMt: {
        thresholdConfig: {},
      },
      form,
      selected: Array<MonitorType>(),
      signalMap: {},
    };
  },
  async mounted() {
    await this.getSignalTypes();
    await this.getSignalValues();
  },
  methods: {
    async getSignalTypes() {
      try {
        const res = await axios.get('/SignalTypes');
        this.monitorTypes = res.data;
      } catch (err) {
        throw new Error('failed to get signal types');
      }
    },
    async getSignalValues() {
      try {
        const res = await axios.get('/SignalValues');
        this.signalMap = res.data;
      } catch (err) {
        throw new Error('failed to get signal types');
      }
    },
    getMonitorTypeValue(mt: string) {
      let signalValues: any = this.signalMap;
      if (signalValues[mt] === undefined) return '-';
      else if (signalValues[mt] === true) return '1';
      else return '0';
    },
    justify(mt: any) {
      if (mt.span === '') mt.span = null;
      if (mt.span_dev_internal === '') mt.span_dev_internal = null;
      if (mt.span_dev_law === '') mt.span_dev_law = null;
      if (mt.zd_internal === '') mt.zd_internal = null;
      if (mt.zd_law === '') mt.zd_law = null;
      if (mt.std_internal === '') mt.std_internal = null;
      if (mt.std_law === '') mt.std_law = null;
      if (mt.levelSeq) {
        try {
          let levelSeq = mt.levelSeq as string;
          let levels = levelSeq.split(',').map(t => parseFloat(t));
          mt.levels = levels;
        } catch (err) {}
      }
    },
    checkLevel(levelSeq: string | undefined): boolean {
      try {
        if (levelSeq === undefined) return true;

        let levels = levelSeq.split(',').map(t => parseFloat(t));

        if (levels.length >= 1 && levels.every(l => !isNaN(l))) return true;
        else {
          this.$bvModal.msgBoxOk(`${levelSeq}不是有效的分級!`);
          return false;
        }
      } catch (err) {
        this.$bvModal.msgBoxOk(`${levelSeq}不是有效的分級!`);
        return false;
      }
    },
    save() {
      const all = [];
      for (const mt of this.monitorTypes) {
        if (mt.dirty) {
          this.justify(mt);
          all.push(axios.put(`/MonitorType/${mt._id}`, mt));
        }
      }

      Promise.all(all).then(() => {
        this.getSignalTypes();
        this.$bvModal.msgBoxOk('成功');
      });
    },
    markDirty(item: any) {
      item.dirty = true;
    },
    onMtSelected(items: Array<MonitorType>) {
      this.selected = items;
    },
    async addMt() {
      this.form._id = `SIGNAL${this.monitorTypes.length + 1}`;
      this.form.desp = `訊號${this.monitorTypes.length + 1}`;
      this.$bvModal.show('addSignalTypeModal');
    },
    async addSignalType() {
      let mt: MonitorType = {
        _id: this.form._id,
        desp: this.form.desp,
        unit: 'N/A',
        prec: 2,
        order: 1000 + this.monitorTypes.length,
        signalType: true,
      };
      try {
        const resp = await axios.post(`/MonitorType/${mt._id}`, mt);
        if (resp.status === 200) this.getSignalTypes();
      } catch (err) {
        throw new Error('failed to get signal types');
      }
    },
    async removeMt() {
      let deletedMts = this.selected.map(p => p._id);
      let ret = await this.$bvModal.msgBoxConfirm(
        `請確認要刪除${deletedMts.join(',')}測項`,
      );
      if (ret === true) {
        try {
          let allP = deletedMts.map(_id => {
            return axios.delete(`/MonitorType/${_id}`);
          });
          await Promise.all(allP);
          this.getSignalTypes();
        } catch (err) {
          throw new Error('Failed to delete mt');
        }
      }
    },
    async setMonitorTypeValue(mt: string, bit: boolean) {
      try {
        const resp = await axios.get(`/SetSignal/${mt}/${bit}`);
      } catch (err) {
        throw new Error('failed to toggle mt');
      }
    },
  },
});
